.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.storyUser:hover {
    transform: scale(1.05);
}

.storyUser:hover img {
    transform: scale(1.05);
}

.storyUser:hover span {
    transform: scale(1.05);
}

.storyUser:hover h6 {
    transform: translateY(-10%);
}

.custom-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 0;
    display: none;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.custom-width {
    width: 20.6%;
}

.custom-color-footer-aside {
    color: #8E9AA6
}

.intl-tel-input .country-list {
    width: 100%;
    border-radius: 10px;
}

.message-control {
    max-width: 300px;
    max-height: 1000px;
    word-wrap: break-word;
    text-overflow: ellipsis;
}

.costom-control {
    max-width: 280px;
    max-height: none;
    word-wrap: break-word;
    text-overflow: ellipsis;
}

.control-content-post {
    max-width: 100%;
    max-height: none;
    word-wrap: break-word;
    text-overflow: ellipsis;
    flex-wrap: wrap;
}

.z-im {
    z-index: 5;
}

.height-room {
    max-height: calc(100vh - 60px - 100px);
}

.date-separator {
    display: flex;
    align-items: center;
    padding: 10px;
}

.date-separator hr {
    flex-grow: 1;
    border: 0;
    border-top: 1px solid #000;
    margin: 0 10px;
}

.costom-with {
    width: 46.4%
}

.h-w {
    height: 240px;
    max-width: 300px;
}

.sizewidth {
    width: 61.7%;
}

.mb {
    margin-bottom: 65px;
}

.bgagrey {
    background-color: #EEEFF1;
}

.bouton-survol:hover {
    background-color: #F0F9FE;
    color: #F0F9FE;
}

.bgmsg {
    background-color: #F0F9FE;
}

/* Masque les barres de défilement tout en permettant le défilement */
#container-msg {
    scrollbar-width: none;
    /* Pour Firefox */
    -ms-overflow-style: none;
    /* Pour IE et Edge */
}

#container-msg::-webkit-scrollbar {
    display: none;
    /* Pour Chrome, Safari et Opera */
}